/* body {
  color: white;
} */

.footer {
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 1rem;
  background: midnightblue;
  box-shadow: 0px 9px 28px -1px rgba(54, 54, 59, 0.83);
}

.footer ul {
  color: white;
}

.footer a {
  color: white;
}

.footer-container {
  max-width: 1440px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
}

.column1 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 1rem 1rem;
}

.address {
  display: flex;
}

.location {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}

.location p {
  line-height: 27px;
  margin-top: 25px;
  text-align: left;
}

.footer h2 {
  color: white;
}

.footer p {
  color: white;
}

.phone {
  margin-bottom: 1rem;
  display: flex;
}

.phone p {
  margin-top: 20px;
  cursor: pointer;
  border-radius: 30px;
  padding: 3px 5px;
}

.phone p:hover {
  box-shadow: 0px 2px 20px -5px rgba(255, 255, 255, 0.83);

  transition: linear 0.5s;
}

.email {
  display: flex;
}

.email p {
  cursor: pointer;
  border-radius: 30px;
  padding: 3px 5px;
}

.email p:hover {
  box-shadow: 0px 2px 20px -5px rgba(255, 255, 255, 0.83);
  transition: linear 0.5s;
}

.column2 ul {
  list-style: circle;
  font-size: 20px;
  margin-top: 1rem;
}

.column2 ul li {
  line-height: 30px;
}

.column2 ul li:hover {
  transform: scale(1.05);
  transition: transform linear 0.3s;
}

.column3 ul {
  list-style: circle;
  font-size: 20px;
  margin-top: 1rem;
}

.column3 ul li {
  line-height: 30px;
}

.column3 ul li:hover {
  transform: scale(1.05);
  transition: transform linear 0.3s;
}

.column4 {
  display: flex;
  flex-direction: column;
}

.social {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.column4 h4 {
  font-size: 1.8rem;
  margin-top: 1rem;
}

p {
  font-size: 1.2rem;
}

.socialIcons {
  margin-right: 20px;
}

.socialIcons:hover {
  transform: scale(1.2);
  transition: transform linear 0.3s;
}

.email h3 {
  margin-right: 2rem;
}

.phone h3 {
  margin-right: 2rem;
}

@media screen and (max-width: 1080px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
  .column4 {
    display: flex;
  }
  .column4 .contact {
    margin-left: 10px;
  }
  .social {
    /* justify-content: space-between; */
    padding-bottom: 1rem;
    margin: 20px 30px;
  }
  .column2 {
    margin-left: 30px;
  }
  .column3 {
    margin-left: 30px;
  }
  .location {
    margin-left: 10px;
  }
  .email h3 {
    margin-right: 2px;
  }
  .phone h3 {
    margin-right: 2px;
  }
}
@media (max-width: 290px) {
  .location {
    margin-left: 0px;
  }
}
@media (width: 820px) {
  .location__symbol {
    height: 30px;
    width: 30px;
    margin-top: 20px;
  }
}

@media (width: 768px) {
  .location__symbol {
    height: 30px;
    width: 30px;
    margin-top: 20px;
  }
}
