.home {
  height: 100%;
  width: 100%;
}

/* header */

.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.homeImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.mask::after {
  content: "";
  width: 100%;

  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}

.home .content123 {
  position: absolute;
  transform: translate(-55%, -50%);
  justify-content: center;
  top: 50%;
  left: 54.5%;
  text-align: center;
  width: 90vw;
}

/* .home .content123 h1 {
  font-size: 8rem;
  padding: 0 auto;
  color: white;
} */

.home .content123 p {
  font-size: 4rem;
  font-weight: 400;
  color: white;
  display: inline;
}

/* main-content */

/* #main-content h1 {
  text-align: center;
  margin-top: 20px;
  font-size: 4rem;
  color: midnightblue;
} */

#main-content {
  display: flex;
  padding: 30px 60px;
}

#main-content h1 {
  margin-top: 10px;
}

#main-content p {
  text-align: center;
  margin-bottom: 40px;
  width: 1000px;
  padding: 0px 25px;
}

.abt-content {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
}

.abt-content button {
  width: 125px;
  height: 50px;
  background-color: midnightblue;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  color: white;
  font-size: 20px;
}

.abt-content button:hover {
  box-shadow: none;
  transform: scale(0.98);
}

.abt-content button a {
  text-decoration: none;
  color: white;
}

.abt-img img {
  width: 100%;
}

.sector-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  grid-gap: 50px;
  padding: 20px 0px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  width: 80%;
  margin: 50px auto;
}

.sector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sector p {
  text-transform: capitalize;
}

#Work {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center; */
  margin-bottom: 80px;
}

.btn__work {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.serviceHeader {
  margin-top: 10px;
}

.servicesHomeBtn {
  background-color: midnightblue;
  text-decoration: none;
  padding: 20px;
  color: #fff;
  font-size: 20px;
  border-radius: 30px;
  position: relative;
  box-shadow: 0px 5px 20px -1px rgba(2, 2, 2, 0.83);
}

.servicesHomeBtn:hover {
  box-shadow: 0px 5px 10px -1px rgba(2, 2, 2, 0.83);
}

/* .arrowRigth {
  margin-left: 10px;
  padding-top: 15px;
} */

@media screen and (max-width: 1080px) {
  .mask {
    height: 70vh;
  }

  .home .content123 {
    position: absolute;
    transform: translate(-55%, -50%);
    justify-content: center;
    top: 45%;
    left: 55%;
    text-align: center;
  }

  .home .content123 p {
    font-size: 2rem;
    color: #fff;
  }

  #main-content h1 {
    font-size: 2.5rem;
  }
  .sector-content {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 20px;
  }
  .sector img {
    /* height: 20px; */
    width: 100%;
    height: auto;
  }
  #main-content {
    flex-direction: column;
  }
  #main-content h1 {
    margin-top: 0px;
  }
  #main-content p {
    width: auto;
    padding: 0px 0px;
  }
}

@media (width: 820px) {
  .home .content123 {
    transform: translate(-55%, -40%);
    top: 42%;
    left: 53%;
    width: 60vw;
  }
  .home .content123 p {
    font-size: 2.6rem;
    color: #fff;
  }
  #main-content {
    align-items: center;
  }
  .abt-img img {
    width: auto;
  }
  .sector img {
    width: 50%;
    height: auto;
  }
}

@media (width: 768px) {
  .home .content123 {
    transform: translate(-55%, -40%);
    top: 42%;
    left: 53%;
    width: 60vw;
  }

  .home .content123 p {
    font-size: 2.6rem;
    color: #fff;
  }
  #main-content {
    align-items: center;
  }
  .abt-img img {
    width: auto;
  }
  .sector img {
    width: 50%;
    height: auto;
  }
}
