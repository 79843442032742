.internship__container {
  padding-top: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.internship__content {
  margin-top: 50px;
}

.internship__content h3 {
  visibility: hidden;
}

.internship__container img {
  margin-top: 50px;
  margin-bottom: 120px;
  width: 50%;
  position: relative;
}

.internship__content button {
  background-color: midnightblue;
  position: absolute;
  text-transform: capitalize;
  font-size: 25px;
  color: white;
  margin: 100px auto 230px;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  top: 340px;
  left: 700px;
}

@media (max-width: 1080px) {
  .internship__container {
    padding-top: 90px;
  }

  .internship__container h1 {
    padding: 0px 20px;
  }
  .internship__content {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
  }
  .internship__content h3 {
    padding: 0px 20px;
  }
  .internship__content img {
    display: none;
  }

  .internship__content button {
    position: static;
    top: 50%;
    left: 0;
    height: 100px;
    width: 350px;
    border-radius: 30px;
  }
}
