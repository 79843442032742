* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.techPool {
  margin-bottom: 50px;
}
h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.tech-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin: 5px 8%;
}

.tech {
  border: 2px solid #ccc;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.tech img {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  align-self: center;
  object-fit: contain;
}

.tech img:hover {
  transform: scale(1.5);
  transition: transform 0.6s ease-out;
}
.tech:hover {
  border: 2px solid midnightblue;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

@media (max-width: 1080px) {
  .tech-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 5px 5%;
  }
  .tech {
    height: 175px;
    width: 175px;
  }
}

@media (width: 768px) {
  .tech-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 5px 8%;
  }
  .tech {
    height: 200px;
    width: 200px;
  }
}
@media (width: 820px) {
  .tech-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 5px 10%;
  }
  .tech {
    height: 200px;
    width: 200px;
  }
}
