.services {
  padding-top: 3rem;
  height: auto;
  /* width: 100vw; */
}
/* .services {
} */

.services-header {
  height: 20vh;
  margin-top: 70px;
  /* width: 100vw; */
}

.services h1 {
  font-size: 4rem;
}
.DevelopmentR {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 100px;
}

.webImage {
  margin-bottom: 30px;
}

.ContentR h2 {
  font-size: 3rem;
  margin-top: -80px;
}
.ContentR p {
  margin-top: 20px;
  text-align: left;
  max-width: 700px;
}
.DevelopmentL {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 100px;
}
.ContentL {
  margin-left: 50px;
}
.ContentL h2 {
  font-size: 3rem;
  margin-top: -80px;
}
.ContentL p {
  margin-top: 20px;
  text-align: left;
  max-width: 700px;
}
.modernImage img {
  margin-left: 30px;
}

@media (max-width: 1080px) {
  .services {
    padding-top: 0rem;
    height: auto;
  }
  .services-header {
    margin-top: 20px;
    height: 5vh;
  }
  .services h1 {
    font-size: 3rem;
    left: 1%;
  }
  .services-container {
    display: flex;
    flex-direction: column;
  }
  .DevelopmentR {
    flex-direction: column;
    margin: 60px 20px;
  }
  .ContentR h2 {
    font-size: 2rem;
    margin-top: -80px;
    text-align: center;
  }
  .ContentR p {
    text-align: justify;
  }
  .webContent h2 {
    text-align: center;
    margin-top: 0px;
  }
  .DevelopmentL {
    flex-direction: column-reverse;
    margin: 60px 20px;
  }
  .ContentL {
    margin-left: 0px;
  }
  .ContentL h2 {
    font-size: 2rem;
    text-align: center;
    margin-top: -80px;
  }
  .ContentL p {
    text-align: justify;
  }

  .modernImage img {
    margin-left: 0px;
  }

  img {
    height: 350px;
  }
}

@media (width: 820px) {
  .services h1 {
    font-size: 4rem;
  }
}

@media (width: 768px) {
  .services-header img {
    height: 40vh;
    width: 100vw;
    object-fit: cover;
  }
  .services h1 {
    font-size: 4rem;
  }
}
