* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact-container {
  padding-top: 7rem;
  height: 140vh;
  /* margin-bottom: 100px; */
  /* display: flex; */
}

.outlay {
  background-image: url("../../Images/contactImg.png");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  height: 83%;
  width: auto;
  margin: 0 50px;
  border: none;
  border-radius: 30px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}
/* .outlay .btns button {
  height: 50px;
  width: 150px;
  color: white;
  background-color: rgb(77, 76, 76);
  margin-top: 80px;
  margin-bottom: 100px;
  font-size: 20px;
  border-radius: 15px;
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 1);
} */

.outlay .btns button:hover {
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  transform: scale(0.98);
}

.India {
  margin-left: 120px;
}

.canada {
  margin-left: 50px;
}

.contact1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* flex-direction: column; */
  position: absolute;
  right: 100px;
  top: 50px;
  background-color: white;
  border: 3px solid midnightblue;
  height: auto;
  width: 40%;
  border-radius: 30px;
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 1);
  /* grid-template-columns: repeat(1, 1fr); */
}

.contact1 input {
  height: 40px;
  background-color: transparent;
  outline: none;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 250px;
  display: inline-block;
  font-size: 20px;
}

.contact1 form {
  display: flex;
  /* grid-template-columns: repeat(1, 1fr); */
  flex-direction: column;
  /* text-align: left; */
  align-items: center;
  justify-content: center;
}

.contact1 label {
  font-size: large;
  font-weight: 600;
  display: inline-block;
  width: 120px;
}
.conHeader {
  text-transform: uppercase;
  margin-top: 20px;
}

textarea {
  font-size: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px 10px;
  margin: 10px 0;
  display: inline-block;
}

.submitBtn {
  height: 50px;
  width: 100px;
  margin: 20px 0;
  border-radius: 20px;
  color: white;
  background-color: midnightblue;
  font-size: large;
}

@media (max-width: 1080px) {
  .contact-container {
    padding-top: 0;
    height: auto;
  }
  .outlay {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    position: relative;
    width: 90%;
    height: 900px;
    margin: 20px auto;
    background-image: url("../../Images/outlay.png");
  }
  .outlay .btns {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 1%;
    left: 28%;
  }
  /* .outlay .btns button {
    margin-top: 20px;
    margin-bottom: 0px;
  } */
  /* .India {
    margin-left: 0px;
  }

  .canada {
    margin-left: 0px;
  } */

  .contact1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    position: absolute;
    right: 18px;
    top: 100px;
    height: auto;
    width: 90%;
  }
  textarea {
    font-size: 12px;
    margin-bottom: 0;
  }
  .contact1 label {
    font-size: 16px;
    font-weight: 600;
    width: 90px;
  }
  .contact1 input {
    width: 180px;
  }
}

@media (width: 768px) {
  .contact-container {
    height: auto;
  }
  .outlay {
    height: 75vh;
    /* background-image: url("../../Images/contactImg2.png"); */

    margin-bottom: 30px;
  }
  .outlay .btns {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 3%;
    left: 24%;
  }
  .canada {
    margin-left: 50px;
  }
  .contact1 {
    right: 35px;
    top: 70px;
  }
  textarea {
    font-size: 20px;
    margin-bottom: 0;
  }
  .contact1 label {
    /* font-size: px; */
    font-weight: 600;
    width: 120px;
  }
  .contact1 input {
    width: 200px;
  }
}

@media (width: 820px) {
  .contact-container {
    height: auto;
  }
  .outlay {
    height: 70vh;
    /* background-image: url("../../Images/contactImg2.png"); */
    background-position: top;
    margin-bottom: 30px;
  }
  .outlay .btns {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 3%;
    left: 24%;
  }
  .canada {
    margin-left: 50px;
  }
  .contact1 {
    right: 35px;
    top: 100px;
  }
  textarea {
    font-size: 20px;
    margin-bottom: 0;
  }
  .contact1 label {
    /* font-size: px; */
    font-weight: 600;
    width: 120px;
  }
  .contact1 input {
    width: 200px;
  }
}
