* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about {
  height: auto;
  padding-top: 100px;
  display: flex;
}

.about-content {
  display: flex;
  flex-direction: column;
  flex: 100%;
  margin-top: 30px;
  padding: 0 50px;
  align-items: center;
}

.about-content p {
  text-align: center;
  padding: 20px 70px;
}

.about-img img {
  width: 100%;
  object-fit: contain;
  margin-left: -40px;
}

.counter-container {
  margin: -80px auto 70px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* box-shadow: 0px 2px 20px -10px rgba(54, 54, 59, 0.83); */
}

.experience-container {
  display: flex;
  padding: 75px 75px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("../../Images/workExperience.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  border-right: 1px solid black;
}

.experience-container h1 {
  font-size: 3.5rem;
  margin-top: 20px;
  margin-right: 10px;
  font-weight: 900;
}

.experience-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}

.client-container {
  display: flex;
  padding: 75px 75px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("../../Images/client.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-right: 1px solid black;
  /* background-color: #ccc; */
}

.client-container h1 {
  font-size: 3.5rem;
  margin-top: 20px;
  margin-right: 10px;
  font-weight: 900;
}

.client-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}

.project-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-image: url("../../Images/project.png");
  background-repeat: no-repeat;
  background-position: center;
  border-right: 1px solid black;
  background-size: contain;

  /* background-color: #ddd; */
  padding: 75px 75px;
}

.project-container h1 {
  margin-top: 20px;
  margin-right: 10px;
  font-size: 3.5rem;
  font-weight: 900;
}

.project-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}

.codes-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 75px 75px;
  background-image: url("../../Images/codes.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.codes-container h1 {
  margin-top: 20px;
  margin-right: 10px;
  font-size: 3.5rem;
  font-weight: 900;
}

.codes-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}
.technologies__about {
  margin-top: 50px;
  padding: 20px 100px 0px 100px;
}
.technologies__work {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  /* margin-bottom: 30px; */
}
.content__img img {
  height: 75%;
  width: auto;
  margin-left: 80px;
  /* opacity: 1; */
}
.content__work {
  padding-right: 100px;
}

@media (max-width: 1080px) {
  .about {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    height: auto;
    padding-top: 40px;
    /*flex-direction: column-reverse; */
  }
  .about-content {
    margin-top: 0px;
    padding: 0;
    width: 100vw;
  }

  .about-content p {
    padding: 10px;
  }

  .about-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-img img {
    height: 390px;
    width: auto;
    margin-bottom: 20px;
    margin-left: 0px;

    /* justify-content: center; */
  }
  .about-img img {
    width: 80%;
  }
  .technologies__about {
    margin-top: 20px;
  }
  .content__img {
    display: none;
  }
  .technologies__about {
    padding: 0px;
  }
  .technologies__work {
    display: flex;
    justify-content: center;
    margin-top: 0px;

    margin-bottom: 30px;
  }
  .content__work {
    padding-right: 0px;
  }
}

@media (max-width: 750px) {
  .counter-container {
    margin: auto;
    display: flex;
    /* grid-template-columns: repeat(1, 1fr); */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* box-shadow: 0px 2px 20px -10px rgba(54, 54, 59, 0.83); */
  }
  .experience-container {
    width: 80%;
    border-right: none;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  .client-container {
    width: 80%;
    border-right: none;
    border-bottom: 1px solid black;
  }
  .project-container {
    width: 80%;
    border-right: none;
    border-bottom: 1px solid black;
  }
  .codes-container {
    width: 80%;
    border-bottom: 1px solid black;
  }
}

@media (width: 820px) {
  .counter-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .experience-container {
    padding: 40px 40px;
    border-bottom: 1px solid black;
  }
  .client-container {
    padding: 40px 40px;
    border-bottom: 1px solid black;
  }
  .project-container {
    padding: 40px 40px;
  }
  .codes-container {
    padding: 40px 40px;
  }
}

@media (width: 768px) {
  .counter-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .experience-container {
    padding: 30px 30px;
    border-bottom: 1px solid black;
  }
  .client-container {
    padding: 30px 30px;
    border-bottom: 1px solid black;
  }
  .project-container {
    padding: 30px 30px;
  }
  .codes-container {
    padding: 30px 30px;
  }
}
