* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.career-container {
  padding-top: 100px;
  height: auto;
  align-items: center;
}

.JoinHead {
  display: flex;
  padding: 0 100px;
}

.JoinContent h1 {
  margin-top: 70px;
  text-align: center;
}

.JoinContent p {
  text-align: left;
  max-width: 800px;
}

.benefitTitle {
  padding: 0 100px;
}

.benefitTitle h1 {
  border-bottom: 2px solid #ccc;
  padding-bottom: 20px;
}

.benefitTitle p {
  text-align: center;
  letter-spacing: 2px;
  font-size: 25px;
}

.benefitContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 50px;
  padding: 20px 0px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  width: 80%;
  margin: 50px auto;
}

.benefitItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
}

.benefitItem img {
  height: 125px;
  width: 125px;
  margin-bottom: 10px;
}

.companyOpportunities {
  margin-top: 100px;
  padding: 0 90px;
}

.opportunity {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid #ccc;
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 5px 0;
}

.OpportunityHeader {
  display: flex;
  align-items: center;
}

.OpportunityHeader h2 {
  margin-left: 10px;
}

.opportunityContent {
  display: none;
  margin-bottom: 30px;
}

.activeExpand {
  display: block;
}

.opportunityContent p {
  text-align: justify;
}
.opportunityContent h2 {
  margin: 30px 0px 10px 0px;
}

.opportunityContent li {
  font-size: 17px;
  line-height: 25px;
}

.opportunityContent button {
  padding: 7px 10px;
  margin: 20px 0px 0px 0px;
  color: white;
  background-color: midnightblue;
  border-radius: 20px;
  font-size: 20px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.opportunityContent button:hover {
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 0.83);
}

.extraOpportunity {
  display: flex;
  margin: 70px 70px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
}

.extraOpportunity h1 {
  width: 500px;
}

.extraContent {
  position: relative;
}

.extraContent img {
  width: 70%;
}

.extraContent button {
  position: absolute;
  background-color: midnightblue;
  font-size: 25px;
  color: white;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  top: 33%;
  left: 61%;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.extraContent button:hover {
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 0.83);
}

.join__content {
  padding: 70px;
}

.join__content h2 {
  margin: 10px auto;
}

.join__container1 {
  padding: 15px 95px;
  width: 50%;
  color: white;
  margin: auto;
  border: 2px solid #ccc;
  background-color: rgba(25, 25, 112);
}
.join__container2 {
  padding: 15px 95px;
  width: 50%;
  margin: auto;
  border-top: none;
  /* color: midnightblue; */
  background-color: white;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
}
.join__container3 {
  padding: 15px 95px;
  width: 50%;
  margin: auto;
  border-top: none;
  background-color: rgba(25, 25, 112);
  color: white;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
}
.join__container4 {
  padding: 15px 95px;
  width: 50%;
  margin: auto;
  border-top: none;
  /* color: midnightblue; */
  background-color: white;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
}

@media (max-width: 1080px) {
  .career-container {
    padding-top: 30px;
  }
  .JoinHead {
    flex-direction: column;
    padding: 0px 0px;
  }
  .JoinContent h1 {
    margin-top: 0px;
  }
  .JoinContent p {
    text-align: center;
    padding: 0px 30px;
    margin-bottom: 10px;
  }
  .benefitContent {
    grid-template-columns: repeat(2, 1fr);
    /* padding: 20px 20px 20px 20px; */
    /* margin: 0px 0px; */
  }
  .benefitContent p {
    font-size: 12px;
    text-align: center;
  }

  .companyOpportunities {
    padding: 0px 20px;
  }

  .OpportunityHeader img {
    width: 20px;
    height: 20px;
  }
  .OpportunityHeader h2 {
    margin-left: 10px;
    font-size: 20px;
  }
  .extraOpportunity {
    flex-direction: column;
    margin: 100px 70px;
  }
  .extraOpportunity h1 {
    font-size: 30px;
    width: 300px;
  }
  .extraContent img {
    display: none;
  }
  .extraContent button {
    margin-bottom: 50px;
    left: -100px;
    border-radius: 30px;
    width: 200px;
    height: 80px;
  }
  .join__content {
    padding: 30px 70px 30px 70px;
  }
  .join__container1,
  .join__container2,
  .join__container3,
  .join__container4 {
    padding: 10px 20px;
    height: 40%;
    width: 100%;
  }

  .join__container1 h2,
  .join__container2 h2,
  .join__container3 h2,
  .join__container4 h2 {
    font-size: 20px;
  }
}

@media (width: 820px) {
  .JoinHead {
    align-items: center;
  }
}

@media (width: 768px) {
  .JoinHead {
    align-items: center;
  }
}
