@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,800&display=swap);
body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home {
  height: 100%;
  width: 100%;
}

/* header */

.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.homeImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.mask::after {
  content: "";
  width: 100%;

  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}

.home .content123 {
  position: absolute;
  -webkit-transform: translate(-55%, -50%);
          transform: translate(-55%, -50%);
  justify-content: center;
  top: 50%;
  left: 54.5%;
  text-align: center;
  width: 90vw;
}

/* .home .content123 h1 {
  font-size: 8rem;
  padding: 0 auto;
  color: white;
} */

.home .content123 p {
  font-size: 4rem;
  font-weight: 400;
  color: white;
  display: inline;
}

/* main-content */

/* #main-content h1 {
  text-align: center;
  margin-top: 20px;
  font-size: 4rem;
  color: midnightblue;
} */

#main-content {
  display: flex;
  padding: 30px 60px;
}

#main-content h1 {
  margin-top: 10px;
}

#main-content p {
  text-align: center;
  margin-bottom: 40px;
  width: 1000px;
  padding: 0px 25px;
}

.abt-content {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
}

.abt-content button {
  width: 125px;
  height: 50px;
  background-color: midnightblue;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  color: white;
  font-size: 20px;
}

.abt-content button:hover {
  box-shadow: none;
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.abt-content button a {
  text-decoration: none;
  color: white;
}

.abt-img img {
  width: 100%;
}

.sector-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  grid-gap: 50px;
  padding: 20px 0px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  width: 80%;
  margin: 50px auto;
}

.sector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sector p {
  text-transform: capitalize;
}

#Work {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center; */
  margin-bottom: 80px;
}

.btn__work {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.serviceHeader {
  margin-top: 10px;
}

.servicesHomeBtn {
  background-color: midnightblue;
  text-decoration: none;
  padding: 20px;
  color: #fff;
  font-size: 20px;
  border-radius: 30px;
  position: relative;
  box-shadow: 0px 5px 20px -1px rgba(2, 2, 2, 0.83);
}

.servicesHomeBtn:hover {
  box-shadow: 0px 5px 10px -1px rgba(2, 2, 2, 0.83);
}

/* .arrowRigth {
  margin-left: 10px;
  padding-top: 15px;
} */

@media screen and (max-width: 1080px) {
  .mask {
    height: 70vh;
  }

  .home .content123 {
    position: absolute;
    -webkit-transform: translate(-55%, -50%);
            transform: translate(-55%, -50%);
    justify-content: center;
    top: 45%;
    left: 55%;
    text-align: center;
  }

  .home .content123 p {
    font-size: 2rem;
    color: #fff;
  }

  #main-content h1 {
    font-size: 2.5rem;
  }
  .sector-content {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 20px;
  }
  .sector img {
    /* height: 20px; */
    width: 100%;
    height: auto;
  }
  #main-content {
    flex-direction: column;
  }
  #main-content h1 {
    margin-top: 0px;
  }
  #main-content p {
    width: auto;
    padding: 0px 0px;
  }
}

@media (width: 820px) {
  .home .content123 {
    -webkit-transform: translate(-55%, -40%);
            transform: translate(-55%, -40%);
    top: 42%;
    left: 53%;
    width: 60vw;
  }
  .home .content123 p {
    font-size: 2.6rem;
    color: #fff;
  }
  #main-content {
    align-items: center;
  }
  .abt-img img {
    width: auto;
  }
  .sector img {
    width: 50%;
    height: auto;
  }
}

@media (width: 768px) {
  .home .content123 {
    -webkit-transform: translate(-55%, -40%);
            transform: translate(-55%, -40%);
    top: 42%;
    left: 53%;
    width: 60vw;
  }

  .home .content123 p {
    font-size: 2.6rem;
    color: #fff;
  }
  #main-content {
    align-items: center;
  }
  .abt-img img {
    width: auto;
  }
  .sector img {
    width: 50%;
    height: auto;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.techPool {
  margin-bottom: 50px;
}
h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.tech-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin: 5px 8%;
}

.tech {
  border: 2px solid #ccc;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.tech img {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  align-self: center;
  object-fit: contain;
}

.tech img:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  transition: -webkit-transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}
.tech:hover {
  border: 2px solid midnightblue;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

@media (max-width: 1080px) {
  .tech-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 5px 5%;
  }
  .tech {
    height: 175px;
    width: 175px;
  }
}

@media (width: 768px) {
  .tech-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 5px 8%;
  }
  .tech {
    height: 200px;
    width: 200px;
  }
}
@media (width: 820px) {
  .tech-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 5px 10%;
  }
  .tech {
    height: 200px;
    width: 200px;
  }
}

.navbar {
  height: 80px;
  width: 100%;
  box-shadow: inset;
  background-color: white;
  color: #ccc;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.2rem;
  position: fixed;
  z-index: 1;
  box-shadow: 0px 9px 20px -1px rgba(244, 244, 247, 0.83);
}

.nav-logo {
  text-decoration: none;
}
.nav-logo img {
  height: 100px;
  /* width: 300px; */
}
.nav-links {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 50px;
  list-style: none;
}
.nav-link {
  text-decoration: none;
  color: black;
  font-weight: 700;
  transition: 0.3s all;
}

.nav-link:hover {
  color: skyblue;
}

.nav-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.contact-nav {
  border: 2px solid #222;
  background-color: midnightblue;
  padding: 10px;
  color: white;
  border-radius: 50px;
}

.ServicesDropdown {
  position: relative;
}

.DropDown {
  /* border: 2px solid black; */
  display: none;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  border-radius: 20px;
  background-color: white;
  padding: 10px 10px;
  position: absolute;
  top: 80%;
  left: 45%;
  cursor: pointer;
}

.DropDown.activeDrop {
  display: block;
}

.DropArrow {
  position: absolute;
  top: 32%;
  color: black;
  border: 2px solid #ccc;
  border-radius: 10px;
  margin-left: 4px;
  /* box-shadow: 0px 9px 9px -1px rgba(2, 2, 2, 0.83); */
}

.DropDown ul li {
  list-style: none;
  color: black;
  border-bottom: 2px solid #ccc;

  line-height: 50px;
}

.DropDown ul :nth-last-child(1) {
  border-bottom: none;
}

@media only screen and (max-width: 1080px) {
  .navbar {
    position: relative;
    z-index: 1;
    justify-content: space-between;
    box-shadow: none;
  }

  .nav-logo img {
    margin-left: -20px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 500px;
    justify-content: space-evenly;
    top: 80px;
    left: -100%;
    transition: 0.5s all;
  }
  .nav-links.active {
    background: rgba(192, 192, 192, 1);
    left: 0;
  }

  .nav-item {
    padding: 10px 0;
  }

  a .hide {
    display:none;
  }
  .nav-link {
    font-size: 25px;
    text-transform: uppercase;
    color: black;
  }
  
  
  .nav-link:hover {
    color: skyblue;
  }
  .nav-icon {
    display: flex;
  }
  .contact-nav {
    color: white;
  }
  .DropArrow {
    top: 38%;
    border: 2px solid #222;
  }
  .DropDown {
    top: 400%;
    left: 15%;
  }
}

@media (width: 820px) {
  .DropDown {
    top: 320%;
    left: 64%;
  }
}

@media (width: 768px) {
  .DropDown {
    top: 320%;
    left: 64%;
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about {
  height: auto;
  padding-top: 100px;
  display: flex;
}

.about-content {
  display: flex;
  flex-direction: column;
  flex: 100% 1;
  margin-top: 30px;
  padding: 0 50px;
  align-items: center;
}

.about-content p {
  text-align: center;
  padding: 20px 70px;
}

.about-img img {
  width: 100%;
  object-fit: contain;
  margin-left: -40px;
}

.counter-container {
  margin: -80px auto 70px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* box-shadow: 0px 2px 20px -10px rgba(54, 54, 59, 0.83); */
}

.experience-container {
  display: flex;
  padding: 75px 75px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url(/static/media/workExperience.cf17efd0.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  border-right: 1px solid black;
}

.experience-container h1 {
  font-size: 3.5rem;
  margin-top: 20px;
  margin-right: 10px;
  font-weight: 900;
}

.experience-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}

.client-container {
  display: flex;
  padding: 75px 75px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url(/static/media/client.c5135590.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-right: 1px solid black;
  /* background-color: #ccc; */
}

.client-container h1 {
  font-size: 3.5rem;
  margin-top: 20px;
  margin-right: 10px;
  font-weight: 900;
}

.client-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}

.project-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-image: url(/static/media/project.40969fe6.png);
  background-repeat: no-repeat;
  background-position: center;
  border-right: 1px solid black;
  background-size: contain;

  /* background-color: #ddd; */
  padding: 75px 75px;
}

.project-container h1 {
  margin-top: 20px;
  margin-right: 10px;
  font-size: 3.5rem;
  font-weight: 900;
}

.project-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}

.codes-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 75px 75px;
  background-image: url(/static/media/codes.583bd19c.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.codes-container h1 {
  margin-top: 20px;
  margin-right: 10px;
  font-size: 3.5rem;
  font-weight: 900;
}

.codes-container p {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 900;
}
.technologies__about {
  margin-top: 50px;
  padding: 20px 100px 0px 100px;
}
.technologies__work {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  /* margin-bottom: 30px; */
}
.content__img img {
  height: 75%;
  width: auto;
  margin-left: 80px;
  /* opacity: 1; */
}
.content__work {
  padding-right: 100px;
}

@media (max-width: 1080px) {
  .about {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    height: auto;
    padding-top: 40px;
    /*flex-direction: column-reverse; */
  }
  .about-content {
    margin-top: 0px;
    padding: 0;
    width: 100vw;
  }

  .about-content p {
    padding: 10px;
  }

  .about-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-img img {
    height: 390px;
    width: auto;
    margin-bottom: 20px;
    margin-left: 0px;

    /* justify-content: center; */
  }
  .about-img img {
    width: 80%;
  }
  .technologies__about {
    margin-top: 20px;
  }
  .content__img {
    display: none;
  }
  .technologies__about {
    padding: 0px;
  }
  .technologies__work {
    display: flex;
    justify-content: center;
    margin-top: 0px;

    margin-bottom: 30px;
  }
  .content__work {
    padding-right: 0px;
  }
}

@media (max-width: 750px) {
  .counter-container {
    margin: auto;
    display: flex;
    /* grid-template-columns: repeat(1, 1fr); */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* box-shadow: 0px 2px 20px -10px rgba(54, 54, 59, 0.83); */
  }
  .experience-container {
    width: 80%;
    border-right: none;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  .client-container {
    width: 80%;
    border-right: none;
    border-bottom: 1px solid black;
  }
  .project-container {
    width: 80%;
    border-right: none;
    border-bottom: 1px solid black;
  }
  .codes-container {
    width: 80%;
    border-bottom: 1px solid black;
  }
}

@media (width: 820px) {
  .counter-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .experience-container {
    padding: 40px 40px;
    border-bottom: 1px solid black;
  }
  .client-container {
    padding: 40px 40px;
    border-bottom: 1px solid black;
  }
  .project-container {
    padding: 40px 40px;
  }
  .codes-container {
    padding: 40px 40px;
  }
}

@media (width: 768px) {
  .counter-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .experience-container {
    padding: 30px 30px;
    border-bottom: 1px solid black;
  }
  .client-container {
    padding: 30px 30px;
    border-bottom: 1px solid black;
  }
  .project-container {
    padding: 30px 30px;
  }
  .codes-container {
    padding: 30px 30px;
  }
}

.services {
  padding-top: 3rem;
  height: auto;
  /* width: 100vw; */
}
/* .services {
} */

.services-header {
  height: 20vh;
  margin-top: 70px;
  /* width: 100vw; */
}

.services h1 {
  font-size: 4rem;
}
.DevelopmentR {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 100px;
}

.webImage {
  margin-bottom: 30px;
}

.ContentR h2 {
  font-size: 3rem;
  margin-top: -80px;
}
.ContentR p {
  margin-top: 20px;
  text-align: left;
  max-width: 700px;
}
.DevelopmentL {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 100px;
}
.ContentL {
  margin-left: 50px;
}
.ContentL h2 {
  font-size: 3rem;
  margin-top: -80px;
}
.ContentL p {
  margin-top: 20px;
  text-align: left;
  max-width: 700px;
}
.modernImage img {
  margin-left: 30px;
}

@media (max-width: 1080px) {
  .services {
    padding-top: 0rem;
    height: auto;
  }
  .services-header {
    margin-top: 20px;
    height: 5vh;
  }
  .services h1 {
    font-size: 3rem;
    left: 1%;
  }
  .services-container {
    display: flex;
    flex-direction: column;
  }
  .DevelopmentR {
    flex-direction: column;
    margin: 60px 20px;
  }
  .ContentR h2 {
    font-size: 2rem;
    margin-top: -80px;
    text-align: center;
  }
  .ContentR p {
    text-align: justify;
  }
  .webContent h2 {
    text-align: center;
    margin-top: 0px;
  }
  .DevelopmentL {
    flex-direction: column-reverse;
    margin: 60px 20px;
  }
  .ContentL {
    margin-left: 0px;
  }
  .ContentL h2 {
    font-size: 2rem;
    text-align: center;
    margin-top: -80px;
  }
  .ContentL p {
    text-align: justify;
  }

  .modernImage img {
    margin-left: 0px;
  }

  img {
    height: 350px;
  }
}

@media (width: 820px) {
  .services h1 {
    font-size: 4rem;
  }
}

@media (width: 768px) {
  .services-header img {
    height: 40vh;
    width: 100vw;
    object-fit: cover;
  }
  .services h1 {
    font-size: 4rem;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
}

h1 {
  text-transform: uppercase;
}

.team__header {
  padding-top: 9rem;
}
.team__header h1 {
  font-size: 3rem;
}

.team-container {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* padding: 5%; */
}

.img-container img {
  height: 70%;
  width: auto;
  margin-top: 50px;
  opacity: 1;
}

.content {
  margin-top: 70px;
  margin-left: 40px;
}

.content img {
  margin-top: 50px;
  margin-left: 13%;
  height: 70%;
  width: 70%;
  object-fit: contain;
  /* display: none; */
}

.getStarted {
  text-align: center;
  margin-bottom: 50px;
  margin-top: -30px;
}

.getStarted a {
  text-decoration: none;
  font-size: larger;
  color: white;
  background-color: midnightblue;
  padding: 1.5rem 3rem;
  border-radius: 30px;
}

.hiringModel {
  margin: 100px 0;
  position: relative;
}

.hiringModel h1 {
  line-height: 100px;
  margin-bottom: 0;
}

.hiringModel h1::before {
  content: "";
  background-color: midnightblue;
  position: absolute;
  left: 25%;
  width: 50%;
  height: 3px;
}

.hiringModel h1::after {
  content: "";
  background-color: midnightblue;
  position: absolute;
  left: 25%;
  bottom: 0;
  width: 50%;
  height: 3px;
}

/* hiring model */

.hiring {
  width: 100%;
  padding: 2rem 1rem;
  /* background: #000; */
}

.card-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

.card {
  border: 3px solid midnightblue;
  /* width: 500px; */
  border-radius: 50px;
  color: black;
  text-align: center;
  padding: 1rem;
  height: auto;
  cursor: pointer;
}
.Three {
  height: 400px;
}
.card h3 {
  font-size: 1.4rem;
  padding: 1.5rem;
}

.card p {
  padding: 10px 0;
}

.card:hover {
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.bar {
  border-bottom: 2px solid black;
  width: 20%;
  margin: 0.5rem auto;
  display: block;
}

.technologies {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 70px;
}

.tech-content {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.technology {
  margin-top: 100px;
}
.web-tech {
  background-color: midnightblue;
  color: white;
  height: 75px;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
  padding: 10px 0;
  margin: 0 auto;
  /* display: inline-block; */
}

.web-techs {
  border: 3px dashed midnightblue;
  border-radius: 20px;
  width: 100%;
  height: auto;
  line-height: 30px;
  padding: 30px 30px;
  margin-top: 50px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.web-techs li {
  list-style: square;
}

.tech-img img {
  height: 75%;
  width: auto;
  margin-left: 80px;
  opacity: 1;
}

/* .slideShow { */
/* overflow: hidden; */
/* width: 400px; */
/* margin: 0 100px; */
/* display: inline-block; */
/* } */

.slider {
  transition: 3s ease 1s;
}

.dedicated-hiring {
  margin-top: -100px;
  margin-bottom: 50px;
}

.dedicated-hiring h1 {
  display: inline-block;
  margin-left: 250px;
}

.dedicated-hiring a {
  font-size: 30px;
  color: white;
  border-radius: 30px;
  background-color: midnightblue;
  padding: 15px;
  margin-left: 40px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.dedicated-hiring a:hover {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  box-shadow: 0px 5px 10px -1px rgba(2, 2, 2, 0.83);
}

@media (max-width: 1080px) {
  .dedicated-hiring {
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .dedicated-hiring h1 {
    margin-left: 0px;
  }
  .dedicated-hiring a {
    margin: auto;
    text-align: center;

    width: 70%;
  }
  .fiArrowRight {
    display: none;
  }
  .card-container {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }
  .Three {
    height: auto;
  }
  .team-container {
    display: flex;
    flex-direction: column;
  }
  .img-container img {
    width: 100%;
  }
  .content {
    text-align: center;
    margin-left: 0px;
  }

  .content img {
    margin: 50px 10px 50px 10px;
    padding: auto;
    /* margin-top: 50px;
    margin-bottom: 50px;
    height: 100%;
    width: 100%;
    padding: 0px 5%;
    margin-left: 10px;
    padding-right: 10%; */
  }
  .technologies {
    display: flex;
    flex-direction: column-reverse;
  }
  .tech-img {
    height: 100%;
    width: 80%;
    overflow: hidden;
    margin-left: 10%;
  }
  .tech-img img {
    object-fit: contain;
    margin-left: 0;
    width: 100%;
    height: 100%;
  }
  /* .tech-content {
    grid-template-columns: repeat(1, 1fr);
  } */

  .techs {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 30px;
    /* height: 100px; */
  }

  .tech-content {
    height: auto;
    display: flex;
    /* align-items: center;
    justify-content: center; */
  }

  .web-techs {
    height: auto;
    width: 90%;
    margin: 20px auto;
    /* margin-left: auto; */
  }
  /* .mobile-techs {
    height: auto;
    width: 90%;
  }
  .other-techs {
    height: auto;
    width: 90%;
  } */
  .web-tech {
    margin-top: 20px;
  }
}

/* @-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact-container {
  padding-top: 7rem;
  height: 140vh;
  /* margin-bottom: 100px; */
  /* display: flex; */
}

.outlay {
  background-image: url(/static/media/contactImg.73209de9.png);
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  height: 83%;
  width: auto;
  margin: 0 50px;
  border: none;
  border-radius: 30px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}
/* .outlay .btns button {
  height: 50px;
  width: 150px;
  color: white;
  background-color: rgb(77, 76, 76);
  margin-top: 80px;
  margin-bottom: 100px;
  font-size: 20px;
  border-radius: 15px;
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 1);
} */

.outlay .btns button:hover {
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.India {
  margin-left: 120px;
}

.canada {
  margin-left: 50px;
}

.contact1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* flex-direction: column; */
  position: absolute;
  right: 100px;
  top: 50px;
  background-color: white;
  border: 3px solid midnightblue;
  height: auto;
  width: 40%;
  border-radius: 30px;
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 1);
  /* grid-template-columns: repeat(1, 1fr); */
}

.contact1 input {
  height: 40px;
  background-color: transparent;
  outline: none;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 250px;
  display: inline-block;
  font-size: 20px;
}

.contact1 form {
  display: flex;
  /* grid-template-columns: repeat(1, 1fr); */
  flex-direction: column;
  /* text-align: left; */
  align-items: center;
  justify-content: center;
}

.contact1 label {
  font-size: large;
  font-weight: 600;
  display: inline-block;
  width: 120px;
}
.conHeader {
  text-transform: uppercase;
  margin-top: 20px;
}

textarea {
  font-size: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px 10px;
  margin: 10px 0;
  display: inline-block;
}

.submitBtn {
  height: 50px;
  width: 100px;
  margin: 20px 0;
  border-radius: 20px;
  color: white;
  background-color: midnightblue;
  font-size: large;
}

@media (max-width: 1080px) {
  .contact-container {
    padding-top: 0;
    height: auto;
  }
  .outlay {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    position: relative;
    width: 90%;
    height: 900px;
    margin: 20px auto;
    background-image: url(/static/media/outlay.bec32e4f.png);
  }
  .outlay .btns {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 1%;
    left: 28%;
  }
  /* .outlay .btns button {
    margin-top: 20px;
    margin-bottom: 0px;
  } */
  /* .India {
    margin-left: 0px;
  }

  .canada {
    margin-left: 0px;
  } */

  .contact1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    position: absolute;
    right: 18px;
    top: 100px;
    height: auto;
    width: 90%;
  }
  textarea {
    font-size: 12px;
    margin-bottom: 0;
  }
  .contact1 label {
    font-size: 16px;
    font-weight: 600;
    width: 90px;
  }
  .contact1 input {
    width: 180px;
  }
}

@media (width: 768px) {
  .contact-container {
    height: auto;
  }
  .outlay {
    height: 75vh;
    /* background-image: url("../../Images/contactImg2.png"); */

    margin-bottom: 30px;
  }
  .outlay .btns {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 3%;
    left: 24%;
  }
  .canada {
    margin-left: 50px;
  }
  .contact1 {
    right: 35px;
    top: 70px;
  }
  textarea {
    font-size: 20px;
    margin-bottom: 0;
  }
  .contact1 label {
    /* font-size: px; */
    font-weight: 600;
    width: 120px;
  }
  .contact1 input {
    width: 200px;
  }
}

@media (width: 820px) {
  .contact-container {
    height: auto;
  }
  .outlay {
    height: 70vh;
    /* background-image: url("../../Images/contactImg2.png"); */
    background-position: top;
    margin-bottom: 30px;
  }
  .outlay .btns {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 3%;
    left: 24%;
  }
  .canada {
    margin-left: 50px;
  }
  .contact1 {
    right: 35px;
    top: 100px;
  }
  textarea {
    font-size: 20px;
    margin-bottom: 0;
  }
  .contact1 label {
    /* font-size: px; */
    font-weight: 600;
    width: 120px;
  }
  .contact1 input {
    width: 200px;
  }
}

/* body {
  color: white;
} */

.footer {
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 1rem;
  background: midnightblue;
  box-shadow: 0px 9px 28px -1px rgba(54, 54, 59, 0.83);
}

.footer ul {
  color: white;
}

.footer a {
  color: white;
}

.footer-container {
  max-width: 1440px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
}

.column1 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 1rem 1rem;
}

.address {
  display: flex;
}

.location {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}

.location p {
  line-height: 27px;
  margin-top: 25px;
  text-align: left;
}

.footer h2 {
  color: white;
}

.footer p {
  color: white;
}

.phone {
  margin-bottom: 1rem;
  display: flex;
}

.phone p {
  margin-top: 20px;
  cursor: pointer;
  border-radius: 30px;
  padding: 3px 5px;
}

.phone p:hover {
  box-shadow: 0px 2px 20px -5px rgba(255, 255, 255, 0.83);

  transition: linear 0.5s;
}

.email {
  display: flex;
}

.email p {
  cursor: pointer;
  border-radius: 30px;
  padding: 3px 5px;
}

.email p:hover {
  box-shadow: 0px 2px 20px -5px rgba(255, 255, 255, 0.83);
  transition: linear 0.5s;
}

.column2 ul {
  list-style: circle;
  font-size: 20px;
  margin-top: 1rem;
}

.column2 ul li {
  line-height: 30px;
}

.column2 ul li:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: -webkit-transform linear 0.3s;
  transition: transform linear 0.3s;
  transition: transform linear 0.3s, -webkit-transform linear 0.3s;
}

.column3 ul {
  list-style: circle;
  font-size: 20px;
  margin-top: 1rem;
}

.column3 ul li {
  line-height: 30px;
}

.column3 ul li:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: -webkit-transform linear 0.3s;
  transition: transform linear 0.3s;
  transition: transform linear 0.3s, -webkit-transform linear 0.3s;
}

.column4 {
  display: flex;
  flex-direction: column;
}

.social {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.column4 h4 {
  font-size: 1.8rem;
  margin-top: 1rem;
}

p {
  font-size: 1.2rem;
}

.socialIcons {
  margin-right: 20px;
}

.socialIcons:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: -webkit-transform linear 0.3s;
  transition: transform linear 0.3s;
  transition: transform linear 0.3s, -webkit-transform linear 0.3s;
}

.email h3 {
  margin-right: 2rem;
}

.phone h3 {
  margin-right: 2rem;
}

@media screen and (max-width: 1080px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
  .column4 {
    display: flex;
  }
  .column4 .contact {
    margin-left: 10px;
  }
  .social {
    /* justify-content: space-between; */
    padding-bottom: 1rem;
    margin: 20px 30px;
  }
  .column2 {
    margin-left: 30px;
  }
  .column3 {
    margin-left: 30px;
  }
  .location {
    margin-left: 10px;
  }
  .email h3 {
    margin-right: 2px;
  }
  .phone h3 {
    margin-right: 2px;
  }
}
@media (max-width: 290px) {
  .location {
    margin-left: 0px;
  }
}
@media (width: 820px) {
  .location__symbol {
    height: 30px;
    width: 30px;
    margin-top: 20px;
  }
}

@media (width: 768px) {
  .location__symbol {
    height: 30px;
    width: 30px;
    margin-top: 20px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.career-container {
  padding-top: 100px;
  height: auto;
  align-items: center;
}

.JoinHead {
  display: flex;
  padding: 0 100px;
}

.JoinContent h1 {
  margin-top: 70px;
  text-align: center;
}

.JoinContent p {
  text-align: left;
  max-width: 800px;
}

.benefitTitle {
  padding: 0 100px;
}

.benefitTitle h1 {
  border-bottom: 2px solid #ccc;
  padding-bottom: 20px;
}

.benefitTitle p {
  text-align: center;
  letter-spacing: 2px;
  font-size: 25px;
}

.benefitContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 50px;
  padding: 20px 0px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
  width: 80%;
  margin: 50px auto;
}

.benefitItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
}

.benefitItem img {
  height: 125px;
  width: 125px;
  margin-bottom: 10px;
}

.companyOpportunities {
  margin-top: 100px;
  padding: 0 90px;
}

.opportunity {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid #ccc;
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 5px 0;
}

.OpportunityHeader {
  display: flex;
  align-items: center;
}

.OpportunityHeader h2 {
  margin-left: 10px;
}

.opportunityContent {
  display: none;
  margin-bottom: 30px;
}

.activeExpand {
  display: block;
}

.opportunityContent p {
  text-align: justify;
}
.opportunityContent h2 {
  margin: 30px 0px 10px 0px;
}

.opportunityContent li {
  font-size: 17px;
  line-height: 25px;
}

.opportunityContent button {
  padding: 7px 10px;
  margin: 20px 0px 0px 0px;
  color: white;
  background-color: midnightblue;
  border-radius: 20px;
  font-size: 20px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.opportunityContent button:hover {
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 0.83);
}

.extraOpportunity {
  display: flex;
  margin: 70px 70px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
}

.extraOpportunity h1 {
  width: 500px;
}

.extraContent {
  position: relative;
}

.extraContent img {
  width: 70%;
}

.extraContent button {
  position: absolute;
  background-color: midnightblue;
  font-size: 25px;
  color: white;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  top: 33%;
  left: 61%;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.extraContent button:hover {
  box-shadow: 0px 9px 10px -1px rgba(2, 2, 2, 0.83);
}

.join__content {
  padding: 70px;
}

.join__content h2 {
  margin: 10px auto;
}

.join__container1 {
  padding: 15px 95px;
  width: 50%;
  color: white;
  margin: auto;
  border: 2px solid #ccc;
  background-color: rgba(25, 25, 112);
}
.join__container2 {
  padding: 15px 95px;
  width: 50%;
  margin: auto;
  border-top: none;
  /* color: midnightblue; */
  background-color: white;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
}
.join__container3 {
  padding: 15px 95px;
  width: 50%;
  margin: auto;
  border-top: none;
  background-color: rgba(25, 25, 112);
  color: white;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
}
.join__container4 {
  padding: 15px 95px;
  width: 50%;
  margin: auto;
  border-top: none;
  /* color: midnightblue; */
  background-color: white;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
}

@media (max-width: 1080px) {
  .career-container {
    padding-top: 30px;
  }
  .JoinHead {
    flex-direction: column;
    padding: 0px 0px;
  }
  .JoinContent h1 {
    margin-top: 0px;
  }
  .JoinContent p {
    text-align: center;
    padding: 0px 30px;
    margin-bottom: 10px;
  }
  .benefitContent {
    grid-template-columns: repeat(2, 1fr);
    /* padding: 20px 20px 20px 20px; */
    /* margin: 0px 0px; */
  }
  .benefitContent p {
    font-size: 12px;
    text-align: center;
  }

  .companyOpportunities {
    padding: 0px 20px;
  }

  .OpportunityHeader img {
    width: 20px;
    height: 20px;
  }
  .OpportunityHeader h2 {
    margin-left: 10px;
    font-size: 20px;
  }
  .extraOpportunity {
    flex-direction: column;
    margin: 100px 70px;
  }
  .extraOpportunity h1 {
    font-size: 30px;
    width: 300px;
  }
  .extraContent img {
    display: none;
  }
  .extraContent button {
    margin-bottom: 50px;
    left: -100px;
    border-radius: 30px;
    width: 200px;
    height: 80px;
  }
  .join__content {
    padding: 30px 70px 30px 70px;
  }
  .join__container1,
  .join__container2,
  .join__container3,
  .join__container4 {
    padding: 10px 20px;
    height: 40%;
    width: 100%;
  }

  .join__container1 h2,
  .join__container2 h2,
  .join__container3 h2,
  .join__container4 h2 {
    font-size: 20px;
  }
}

@media (width: 820px) {
  .JoinHead {
    align-items: center;
  }
}

@media (width: 768px) {
  .JoinHead {
    align-items: center;
  }
}

.btn {
  position: fixed;
  width: 10%;
  left: 45%;
  bottom: 10px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: crimson;
}

.btn:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: -webkit-transform linear 0.2s;
  transition: transform linear 0.2s;
  transition: transform linear 0.2s, -webkit-transform linear 0.2s;
}

.internship__container {
  padding-top: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.internship__content {
  margin-top: 50px;
}

.internship__content h3 {
  visibility: hidden;
}

.internship__container img {
  margin-top: 50px;
  margin-bottom: 120px;
  width: 50%;
  position: relative;
}

.internship__content button {
  background-color: midnightblue;
  position: absolute;
  text-transform: capitalize;
  font-size: 25px;
  color: white;
  margin: 100px auto 230px;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  top: 340px;
  left: 700px;
}

@media (max-width: 1080px) {
  .internship__container {
    padding-top: 90px;
  }

  .internship__container h1 {
    padding: 0px 20px;
  }
  .internship__content {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
  }
  .internship__content h3 {
    padding: 0px 20px;
  }
  .internship__content img {
    display: none;
  }

  .internship__content button {
    position: static;
    top: 50%;
    left: 0;
    height: 100px;
    width: 350px;
    border-radius: 30px;
  }
}

