.btn {
  position: fixed;
  width: 10%;
  left: 45%;
  bottom: 10px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: crimson;
}

.btn:hover {
  transform: scale(1.2);
  transition: transform linear 0.2s;
}
