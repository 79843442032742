* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
}

h1 {
  text-transform: uppercase;
}

.team__header {
  padding-top: 9rem;
}
.team__header h1 {
  font-size: 3rem;
}

.team-container {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* padding: 5%; */
}

.img-container img {
  height: 70%;
  width: auto;
  margin-top: 50px;
  opacity: 1;
}

.content {
  margin-top: 70px;
  margin-left: 40px;
}

.content img {
  margin-top: 50px;
  margin-left: 13%;
  height: 70%;
  width: 70%;
  object-fit: contain;
  /* display: none; */
}

.getStarted {
  text-align: center;
  margin-bottom: 50px;
  margin-top: -30px;
}

.getStarted a {
  text-decoration: none;
  font-size: larger;
  color: white;
  background-color: midnightblue;
  padding: 1.5rem 3rem;
  border-radius: 30px;
}

.hiringModel {
  margin: 100px 0;
  position: relative;
}

.hiringModel h1 {
  line-height: 100px;
  margin-bottom: 0;
}

.hiringModel h1::before {
  content: "";
  background-color: midnightblue;
  position: absolute;
  left: 25%;
  width: 50%;
  height: 3px;
}

.hiringModel h1::after {
  content: "";
  background-color: midnightblue;
  position: absolute;
  left: 25%;
  bottom: 0;
  width: 50%;
  height: 3px;
}

/* hiring model */

.hiring {
  width: 100%;
  padding: 2rem 1rem;
  /* background: #000; */
}

.card-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

.card {
  border: 3px solid midnightblue;
  /* width: 500px; */
  border-radius: 50px;
  color: black;
  text-align: center;
  padding: 1rem;
  height: auto;
  cursor: pointer;
}
.Three {
  height: 400px;
}
.card h3 {
  font-size: 1.4rem;
  padding: 1.5rem;
}

.card p {
  padding: 10px 0;
}

.card:hover {
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.bar {
  border-bottom: 2px solid black;
  width: 20%;
  margin: 0.5rem auto;
  display: block;
}

.technologies {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 70px;
}

.tech-content {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.technology {
  margin-top: 100px;
}
.web-tech {
  background-color: midnightblue;
  color: white;
  height: 75px;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
  padding: 10px 0;
  margin: 0 auto;
  /* display: inline-block; */
}

.web-techs {
  border: 3px dashed midnightblue;
  border-radius: 20px;
  width: 100%;
  height: auto;
  line-height: 30px;
  padding: 30px 30px;
  margin-top: 50px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.web-techs li {
  list-style: square;
}

.tech-img img {
  height: 75%;
  width: auto;
  margin-left: 80px;
  opacity: 1;
}

/* .slideShow { */
/* overflow: hidden; */
/* width: 400px; */
/* margin: 0 100px; */
/* display: inline-block; */
/* } */

.slider {
  transition: 3s ease 1s;
}

.dedicated-hiring {
  margin-top: -100px;
  margin-bottom: 50px;
}

.dedicated-hiring h1 {
  display: inline-block;
  margin-left: 250px;
}

.dedicated-hiring a {
  font-size: 30px;
  color: white;
  border-radius: 30px;
  background-color: midnightblue;
  padding: 15px;
  margin-left: 40px;
  box-shadow: 0px 9px 20px -1px rgba(2, 2, 2, 0.83);
}

.dedicated-hiring a:hover {
  transform: scale(0.98);
  box-shadow: 0px 5px 10px -1px rgba(2, 2, 2, 0.83);
}

@media (max-width: 1080px) {
  .dedicated-hiring {
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .dedicated-hiring h1 {
    margin-left: 0px;
  }
  .dedicated-hiring a {
    margin: auto;
    text-align: center;

    width: 70%;
  }
  .fiArrowRight {
    display: none;
  }
  .card-container {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }
  .Three {
    height: auto;
  }
  .team-container {
    display: flex;
    flex-direction: column;
  }
  .img-container img {
    width: 100%;
  }
  .content {
    text-align: center;
    margin-left: 0px;
  }

  .content img {
    margin: 50px 10px 50px 10px;
    padding: auto;
    /* margin-top: 50px;
    margin-bottom: 50px;
    height: 100%;
    width: 100%;
    padding: 0px 5%;
    margin-left: 10px;
    padding-right: 10%; */
  }
  .technologies {
    display: flex;
    flex-direction: column-reverse;
  }
  .tech-img {
    height: 100%;
    width: 80%;
    overflow: hidden;
    margin-left: 10%;
  }
  .tech-img img {
    object-fit: contain;
    margin-left: 0;
    width: 100%;
    height: 100%;
  }
  /* .tech-content {
    grid-template-columns: repeat(1, 1fr);
  } */

  .techs {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 30px;
    /* height: 100px; */
  }

  .tech-content {
    height: auto;
    display: flex;
    /* align-items: center;
    justify-content: center; */
  }

  .web-techs {
    height: auto;
    width: 90%;
    margin: 20px auto;
    /* margin-left: auto; */
  }
  /* .mobile-techs {
    height: auto;
    width: 90%;
  }
  .other-techs {
    height: auto;
    width: 90%;
  } */
  .web-tech {
    margin-top: 20px;
  }
}

/* @-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
} */
